import queryString from 'query-string';

import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginationProps, PaginationQuery } from './types';
import { ordoxBaseQuery } from './baseQuery';

export type Product = {
  id: string;
  idERP: string;
  name: string;
  productId: string;
  companyId: string;
  cost: number;
  createdAt: string;
  inventory: number;
  priceAfterTax: number;
  priceBeforeTax: number;
  tax: number; // 19%
  taxIdERP: string;
  unit: 'uds' | 'gr' | 'kg' | 'l' | 'cm' | 'm' | 'cm2' | 'm2' | 'ml';
  unitsCount: boolean; // Contable
  updatedAt: string;
  quantity?: number;
  discount?: number;
  notes?: string;
  minQuantity?: number;
  [key: string]: unknown;
};

type FindProductResponse = {
  items: Product[];
};

type FindProductRequest = {
  active?: boolean;
};

export type ListPriceProductItem = {
  id: string;
  productId: string;
  price: number;
  companyId: string;
  product: {
    id: string;
    name: string;
    priceBeforeTax: number;
    sku: string;
  };
  listPriceId: string;
  minQuantity: number;
  isShippingItem: boolean;
};

export type FindListPriceProductRequest = {
  listPriceId: string;
  limit?: number;
  offset?: number;
};

type FindListPriceProductResponse = {
  total: number;
  items: ListPriceProductItem[];
};

const productApi = createApi({
  reducerPath: 'product-api',
  tagTypes: ['product'],
  baseQuery: ordoxBaseQuery(),
  endpoints: ({ query }) => ({
    findProduct: query({
      providesTags: ['product'],
      query: (filters: FindProductRequest) => ({
        url: `products?active=${filters.active}`,
        method: 'GET'
      }),
      transformResponse: (response: FindProductResponse, _, request) => {
        response.items = response.items.map(item => ({
          ...item,
          unit: (item.unit as string) === 'unit' ? 'uds' : item.unit,
        }))

        return response
      },
    }),
    findListPriceProduct: query<FindListPriceProductResponse, FindListPriceProductRequest>({
      providesTags: ['product'],
      query: (filters) => {
        const params = queryString.stringify(filters, { skipNull: true, skipEmptyString: true })
        return {
          url: `products/prices${params ? `?${params}` : ''}`,
          method: 'GET'
        }
      },
    }),
  }),
});

export const {
  useFindProductQuery,
  useFindListPriceProductQuery,
  useLazyFindListPriceProductQuery,
} = productApi;

export default productApi;
