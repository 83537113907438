import { PaginationProps, PaginationQuery } from '@api/query/types';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { debounce, isEqual } from 'lodash';
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { ListRange } from 'react-virtuoso';
import { useDebounce } from 'rooks';
import createVirtualArray from './createVirtualArray';

type UseLazyPaginationProps<T> = {
  initialLimit?: number;
  limit?: number;
  initialExtraQuery?: Record<string, string>;
  trigger(pagination: PaginationQuery): Promise<any>;
  result: {
    status: QueryStatus;
    requestId?: string;
    data?: PaginationProps<T>;
  };
};

const reRenderReducer = (value: number) => value + 1;

const useReRender = () => {
  const [, reRender] = useReducer(reRenderReducer, 0);

  return reRender;
};

const extendPage = (page: PaginationQuery, amount: number) => {
  return {
    offset: Math.max(0, page.offset - amount),
    limit: page.limit + amount * 2,
  };
};

export const useLazyPagination = <T extends unknown>({
  trigger,
  result,
  initialLimit = 25,
  initialExtraQuery = {}
}: UseLazyPaginationProps<T>) => {
  const reRender = useReRender();
  const [viewRange, setViewRange] = useState<ListRange>({
    startIndex: 0,
    endIndex: 50,
  });

  const page = useMemo(
    () => ({
      offset: viewRange?.startIndex || 0,
      limit: viewRange.endIndex - viewRange.startIndex + 1,
    }),
    [viewRange],
  );

  const lock = useRef(false);

  const virtualArray = useMemo(
    () => createVirtualArray<T>(reRender),
    [reRender],
  );

  const { hasPage, getPageFree, putPage, clearArray } = virtualArray;

  const { data } = result;

  const count = useMemo(() => data?.total || 0, [data]);

  const triggerDebounced = useMemo(() => {
    return debounce(trigger, 300);
  }, []);

  const extraQuery = useRef<Record<string, any>>(initialExtraQuery);

  const setExtraQuery = useCallback<
    (action: SetStateAction<Record<string, any>>) => void
  >(async action => {
    const value = extraQuery.current;

    const nextValue = typeof action === 'function' ? action(value) : action;

    if (!isEqual(value, nextValue)) {
      extraQuery.current = nextValue;

      lock.current = true;

      await triggerDebounced({
        offset: 0,
        limit: initialLimit,
        ...nextValue,
      });

      clearArray();
      lock.current = false;
    }
  }, []);

  const resetPagination = useCallback(async () => {
    await trigger({ ...extraQuery.current, offset: 0, limit: initialLimit });
    clearArray();
  }, []);

  useEffect(() => {
    if (lock.current) return;

    const { status, data } = result;
    if (status === QueryStatus.uninitialized) {
      trigger({ ...extraQuery.current, offset: 0, limit: initialLimit });
      return;
    }

    if (status === QueryStatus.pending) return;

    if (status === QueryStatus.fulfilled) {
      virtualArray.ref.length = data.total;

      // if (!hasPage(data.offset, data.items.length)) {
      putPage(data.offset, data.items);
      // }

      if (!hasPage(page.offset, page.limit)) {
        trigger({
          ...extraQuery.current,
          ...getPageFree(extendPage(page, 25)),
        });
      }
    }
  }, [page, count, trigger, result.data, result.status]);

  return {
    setViewRange,
    viewRange,
    virtualArray,
    hasData: virtualArray.ref.length > 0,
    extraQuery,
    setExtraQuery,
    resetPagination,
  };
};
